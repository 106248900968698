:root {

    /* slider controls */
    --slider-arrow-transition-time: .5s;
    --slider-arrow-spacing: 1.5vw;
    --slider-arrow-thickness: 0.1vw;
    --slider-controls-color: #000000;
    --slider-dot-size: 1vh;
    --slider-dot-spacing: 1vw;

    /* slider controls tablet */
    --slider-arrow-spacing-tablet: 1vw;
    --slider-arrow-thickness-tablet: 0.3vw;
    --slider-controls-color-tablet: #000000;
    --slider-dot-size-tablet: 1vh;
    --slider-dot-spacing-tablet: 1vw;

    /* slider behaviour */
    --slide-change-duration: 1s;
    --slide-duration: 7s;
    --slide-out-distance: 50vw;
    --slide-in-distance: 50vw;
    --slide-animation-timing-function: cubic-bezier(.87, 0, .13, 1);

    /* slider behaviour tablet */
    --slide-out-distance-tablet: 100vw;
    --slide-in-distance-tablet: 100vw;

    /* bottom border */
    --slider-bottom-border-thickness: 1px;
    --slider-bottom-border-color: black;
    --slider-bottom-border-spacing: 5vh;

    /* size and scale */
    --slider-width: 60vw;
    --slider-height: 20vh;
    --slider-padding: 10vh;
    --slider-img-height: 15vh; /* should be smaller then slider width */
    --slider-img-max-width: 30vw; /* should be smaller then slider width */

    /* size and scale tablet */
    --slider-width-tablet: 95vw;
    --slider-height-tablet: 20vh;
    --slider-img-height-tablet: 15vh; /* should be smaller then slider width */
    --slider-img-max-width-tablet: 70vw; /* should be smaller then slider width */

    /* size and scale phone */
    --slider-width-phone: 95vw;
    --slider-height-phone: 20vh;
    --slider-img-height-phone: 8vh; /* should be smaller then slider width */
    --slider-img-max-width-phone: 70vw; /* should be smaller then slider width */

    /* text */
    --slider-text-color: #123c62;
    --slider-text-spacing: 3rem;
    --slider-text-font-size: 2vh;
    --slider-text-font-size-tablet: 2vh;
    --slider-text-font-size-phone: 1.8vh;
}

.slider-section {
    width: 100vw;
    border-width: 0 0 var(--slider-bottom-border-thickness) 0;
    border-style: solid;
    border-color: var(--slider-bottom-border-color);
    padding-bottom: var(--slider-bottom-border-spacing);
}

.slider {
    position: relative;
    padding: var(--slider-padding) 0;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

/* normal */
@media screen and (min-width: 1300px){
    .slider {
        width: var(--slider-width);
        height: var(--slider-height);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .slider {
        width: var(--slider-width-tablet);
        height: var(--slider-height-tablet);
    }
}

/* END */

/* controls */

.slider-dots-outer-container {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.slider-dots-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.slider-dot {
    border-radius: 50%;
}

.slider-dot:hover {
    background-color: color-mix(in srgb, var(--slider-controls-color), #ffffff);
}

.slider-active-dot {
    transition: none !important;
    background-color: white !important;
    border-width: 2px;
    border-style: solid;
    border-color: black;
}

.slider-left-arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    transition: all var(--slider-arrow-transition-time) !important;
}

.slider-left-arrow:hover {
    border-color: color-mix(in srgb, var(--slider-controls-color), #ffffff);
}

.slider-right-arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    transition: all var(--slider-arrow-transition-time) !important;
}

.slider-right-arrow:hover {
    border-color: color-mix(in srgb, var(--slider-controls-color), #ffffff);
}

/* normal */
@media screen and (min-width: 1300px){
    .slider-dot {
        height: var(--slider-dot-size);
        width: var(--slider-dot-size);
        background-color: var(--slider-controls-color);
        margin: 0 var(--slider-dot-spacing);
        cursor: pointer;
    }

    .slider-left-arrow {
        left: var(--slider-arrow-spacing);
        border: solid var(--slider-controls-color);
        border-width: 0 var(--slider-arrow-thickness) var(--slider-arrow-thickness) 0;
    }

    .slider-right-arrow {
        right: var(--slider-arrow-spacing);
        border: solid var(--slider-controls-color);
        border-width: 0 var(--slider-arrow-thickness) var(--slider-arrow-thickness) 0;
    }
}

/* tablet */
@media screen and (max-width: 1299px){
    .slider-dot {
        height: var(--slider-dot-size-tablet);
        width: var(--slider-dot-size-tablet);
        background-color: var(--slider-controls-color-tablet);
        margin: 0 var(--slider-dot-spacing-tablet);
        cursor: pointer;
    }

    .slider-left-arrow {
        left: var(--slider-arrow-spacing-tablet);
        border: solid var(--slider-controls-color-tablet);
        border-width: 0 var(--slider-arrow-thickness-tablet) var(--slider-arrow-thickness-tablet) 0;
    }

    .slider-right-arrow {
        right: var(--slider-arrow-spacing-tablet);
        border: solid var(--slider-controls-color-tablet);
        border-width: 0 var(--slider-arrow-thickness-tablet) var(--slider-arrow-thickness-tablet) 0;
    }
}

/* END */

/* image */

.slider-img {
    width: auto;
    margin-bottom: var(--slider-text-spacing);
}

.slider-img-container {
    text-align: center;
}

.slider-previous-img {
    z-index: 1;
    position: absolute;
}

/* normal */
@media screen and (min-width: 1300px){
    .slider-img {
        height: var(--slider-img-height);
        max-width: var(--slider-img-max-width);
    }

    .slider-img-container {
        max-width: var(--slider-img-max-width);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .slider-img {
        height: var(--slider-img-height-tablet);
        max-width: var(--slider-img-max-width-tablet);
    }

    .slider-img-container {
        max-width: var(--slider-img-max-width-tablet);
    }
}

/* phone */
@media screen and (max-width: 650px){
    .slider-img {
        height: var(--slider-img-height-phone);
        max-width: var(--slider-img-max-width-phone);
    }

    .slider-img-container {
        max-width: var(--slider-img-max-width-phone);
    }
}

/* END */

/* text */

.slider-text {
    color: var(--slider-text-color);
    text-align: center;
}

/* normal */
@media screen and (min-width: 1300px){
    .slider-text {
        font-size: var(--slider-text-font-size);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .slider-text {
        font-size: var(--slider-text-font-size-tablet);
    }
}

/* phone */
@media screen and (max-width: 650px){
    .slider-text {
        font-size: var(--slider-text-font-size-phone);
    }
}

/* END */

/* animation related */

.slider-slide-out-left {
    animation: slide-out-left var(--slide-change-duration) both;
    animation-timing-function: var(--slide-animation-timing-function);
}

.slider-slide-out-right {
    animation: slide-out-right var(--slide-change-duration) both;
    animation-timing-function: var(--slide-animation-timing-function);
}

.slider-slide-in-right {
    animation: slide-in-right var(--slide-change-duration) both;
    animation-timing-function: var(--slide-animation-timing-function);
}

.slider-slide-in-left {
    animation: slide-in-left var(--slide-change-duration) both;
    animation-timing-function: var(--slide-animation-timing-function);
}

/* normal */
@media screen and (min-width: 1300px){
    @keyframes slide-out-left {
        to {
            transform: translateX(calc(var(--slide-out-distance) * -1));
            opacity: 0;
        }
    }

    @keyframes slide-out-left {
        to {
            transform: translateX(calc(var(--slide-out-distance) * -1));
            opacity: 0;
        }
    }

    @keyframes slide-out-right {
        to {
            transform: translateX(var(--slide-out-distance));
            opacity: 0;
        }
    }

    @keyframes slide-in-right {
        from {
            transform: translateX(var(--slide-in-distance));
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slide-in-left {
        from {
            transform: translateX(calc(var(--slide-in-distance) * -1));
        }
        to {
            transform: translateX(0);
        }
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    @keyframes slide-out-left {
        to {
            transform: translateX(calc(var(--slide-out-distance-tablet) * -1));
            opacity: 0;
        }
    }

    @keyframes slide-out-left {
        to {
            transform: translateX(calc(var(--slide-out-distance-tablet) * -1));
            opacity: 0;
        }
    }

    @keyframes slide-out-right {
        to {
            transform: translateX(var(--slide-out-distance-tablet));
            opacity: 0;
        }
    }

    @keyframes slide-in-right {
        from {
            transform: translateX(var(--slide-in-distance-tablet));
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slide-in-left {
        from {
            transform: translateX(calc(var(--slide-in-distance-tablet) * -1));
        }
        to {
            transform: translateX(0);
        }
    }
}

/* END */

.show-on-slider-hover {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.slider:hover > .show-on-slider-hover {
    opacity: 1;
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .show-on-slider-hover {
        opacity: 1 !important;
    }
}

