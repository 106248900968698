body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Nugo;
  src: local('Nugo'), url(./fonts/nugo-sans-font/Nugo.ttf) format('truetype');
}

@font-face {
  font-family: Playfair;
  src: local(Playfair),
  url(./fonts/playfair-display-font/PlayfairDisplayRegular-ywLOY.ttf) format('truetype');
}

@font-face {
  font-family: Salma;
  src: local(Salma), url(./fonts/salma-pro-font/SalmaproMedium-yw9ad.otf) format('truetype');
}

@font-face {
  font-family: Salma-Narrow;
  src: local(Salma-Narrow), url(./fonts/salma-pro-font/SalmaproMediumnarrow-9YlVj.otf) format('truetype');
}
