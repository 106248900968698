/* variables */

:root {
    /* header PHONE */
    --nav-menu-padding-phone: 2vh;
    --nav-menu-background-color-phone: #fdfdfd;
    --nav-menu-shadow-phone: 0 3px 5px 1px rgba(0, 0, 0, 0.5);

    /* sections control */
    --nav-menu-section-width: 90vw;
    --nav-menu-controlls-section-width: 100%;
    --header-width: 100vw;
    --header-max-height: 10vh;
    --header-on-scroll-width: 70vw;
    --nav-menu-logo-height: 8vh;
    --nav-menu-vertical-spacing-width: 65vw;

    /* sections control PHONE */
    --nav-menu-logo-height-phone: 8vh;

    /* navigation */
    --nav-menu-item-underline-color: #67b9ff;
    --nav-menu-item-underline-thickness: .2rem;
    --nav-menu-item-underline-bottom-distance: .2rem;
    --nav-menu-item-pick-up-height: -.2rem;
    --nav-menu-items-spacing: 3vw;
    --nav-menu-item-animation-speed: .3s;
    --nav-menu-item-font-color: #ffffff;
    --nav-menu-item-font-size: 1.2rem;

    /* navigation PHONE */
    --nav-burger-animation-speed: 1s;
    --nav-burger-menu-font-size: 5vh;
    --nav-burger-menu-font-color: #3e3e3e;
    --nav-burger-menu-font-letter-spacing: .35rem;
    --nav-burger-menu-font-letter-line-height: 1rem;
    --nav-burger-lang-options-gap: 7vw;
    --nav-burger-lang-menu-font-size: 3.5vh;
    --nav-burger-lang-menu-font-color: #5a5a5a;
    --nav-burger-lang-menu-current-font-color: #67b9ff;
    --nav-burger-lang-menu-current-font-size: 3.75vh;
    --nav-burger-lang-menu-font-letter-spacing: .15rem;
    --nav-burger-lang-menu-font-letter-line-height: 1rem;

    /* separator */
    --nav-menu-vertical-separator-color: #ffffff;
    --nav-menu-vertical-separator-height: 2.5rem;
    --nav-menu-vertical-separator-width: 3px;
    --nav-menu-vertical-separator-margin: 2rem;
    --nav-menu-vertical-separator-border-radius: 24%;

    /* lang options */
    --lang-dropdown-hover-font-color: #484848;
    --lang-dropdown-hover-background-color: none;
    --lang-dropdown-font-size: 1rem;

    /* animation */
    --nav-menu-on-scroll-change-speed: 1s;
    --nav-menu-on-scroll-font-decrease: 1.1;
    --nav-menu-on-scroll-logo-animation-speed: 2s;
    --nav-menu-on-scroll-backgroud-color: #ffffff;
    --nav-menu-on-scroll-border-radius: 20%;
    --nav-menu-on-scroll-border-length: .7rem;
    --nav-menu-on-scroll-shadow: 0 0 .5rem rgba(0, 0, 0, 0.55);
    --nav-menu-on-scroll-items-color: #474f56;
    --nav-menu-on-scroll-navigation-items-spacing: 3vw;
    --nav-menu-on-scroll-logo-margin: 2rem;
    --nav-menu-on-scroll-logo-height: 6vh;
}

/* end */

/* global */

html {
    scroll-behavior: smooth;
}

/* END */

/* Main section */

header {
    height: auto;
    max-height: var(--header-max-height);
    position: fixed;
    z-index: 3;
    width: var(--header-width);
}

/* normal */
@media screen and (min-width: 1301px){
    header{
        transition: all var(--nav-menu-on-scroll-change-speed);
    }

    .header-scrolled {
        background-color: var(--nav-menu-on-scroll-backgroud-color);
        border-bottom-left-radius: var(--nav-menu-on-scroll-border-length) var(--nav-menu-on-scroll-border-radius);
        border-bottom-right-radius: var(--nav-menu-on-scroll-border-length) var(--nav-menu-on-scroll-border-radius);
        box-shadow: var(--nav-menu-on-scroll-shadow);
        margin-left: 15vw;
        margin-right: 15vw;
        width: var(--header-on-scroll-width);
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    header{
        padding: var(--nav-menu-padding-phone) 0 var(--nav-menu-padding-phone) 0;
        background-color: var(--nav-menu-background-color-phone);
        box-shadow: var(--nav-menu-shadow-phone);
    }

    .main-section {
        align-items: center;
        justify-content: space-between;
    }
}

.main-section {
    display: flex;
    width: var(--nav-menu-section-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: .5vh;
    padding: 1vh 0;
}

.main-section-scrolled {
    padding: .5vh 0 !important;
}

/* END */

/* vertical spacing */

.nav-menu-spacing-scrolled {
    width: 0 !important;
    transition: all var(--nav-menu-on-scroll-change-speed);
}

/* normal */
@media screen and (min-width: 1301px){
    .nav-menu-spacing {
        width: var(--nav-menu-vertical-spacing-width);
        transition: all var(--nav-menu-on-scroll-change-speed);
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .nav-menu-spacing {
        width: 0;
        transition: all var(--nav-menu-on-scroll-change-speed);
    }
}

/* END */

/* Navigation style */

.navigation {
    width: var(--nav-menu-controlls-section-width);
    display: flex;
    justify-content: center;
    margin: auto;
    flex: 1;
}

.navigation-scrolled {
    flex: 1.2;
    margin-left: -8rem;
}

/* normal */
@media screen and (min-width: 1301px){
    .navigation {
        width: var(--nav-menu-controlls-section-width);
        display: flex;
        justify-content: center;
        margin: auto;
        flex: 1;
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .navigation {
        display: none;
    }
}

a {
    text-decoration: none;
}

nav {
    display: flex;
}

.nav-menu-item-container {
    background-image: linear-gradient(var(--nav-menu-item-underline-color), var(--nav-menu-item-underline-color));
    background-size: 0 var(--nav-menu-item-underline-thickness);
    margin-left: var(--nav-menu-items-spacing);
    margin-right: var(--nav-menu-items-spacing);
    padding-top: var(--nav-menu-item-underline-bottom-distance);
    padding-bottom: var(--nav-menu-item-underline-bottom-distance);
    background-repeat: no-repeat;
    transition: all var(--nav-menu-on-scroll-change-speed), background-size var(--nav-menu-item-animation-speed);
    background-position: 50% 100%;
    display: flex;
    width: max-content;
}

.nav-menu-item-container-scrolled {
    margin-right: var(--nav-menu-on-scroll-navigation-items-spacing);
    margin-left: var(--nav-menu-on-scroll-navigation-items-spacing);
}

.nav-menu-item-container:hover {
    background-size: 100% var(--nav-menu-item-underline-thickness);
}
.nav-menu-item-container:hover a {
    transform: translateY(var(--nav-menu-item-pick-up-height));
    color: #67b9ff;
}

.nav-menu-item-scrolled {
    color: var(--nav-menu-on-scroll-items-color);
    font-size: calc(var(--nav-menu-item-font-size) / var(--nav-menu-on-scroll-font-decrease));
}

/* end */

/* logo */

.logo-section {
    height: 5vh;
    transition: all calc(var(--nav-menu-on-scroll-logo-animation-speed) / 3);
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1301px){
    svg {
        height: var(--nav-menu-logo-height);
        width: auto;
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .logo-section {

    }

    svg {
        height: var(--nav-menu-logo-height-phone);
        width: auto;
    }
}

svg {
    transition: all var(--nav-menu-on-scroll-logo-animation-speed);
}

nav a {
    display: flex;
    color: var(--nav-menu-item-font-color);
    font-size: var(--nav-menu-item-font-size);
    transition: transform var(--nav-menu-item-animation-speed), color var(--nav-menu-item-animation-speed), font-size var(--nav-menu-on-scroll-change-speed);
}

svg #text-group{
    transition: all calc(var(--nav-menu-on-scroll-logo-animation-speed) / 2);
}

.logo-section-scrolled svg #text-group{
    opacity: 0;
}

.logo-section-scrolled svg {
    margin-left: var(--nav-menu-on-scroll-logo-margin);
    height: var(--nav-menu-on-scroll-logo-height);
    animation: logo-animation var(--nav-menu-on-scroll-logo-animation-speed) both;
}

/* end */

/* right section */

@media screen and (min-width: 1301px){
    .nav-menu-right-section {
        display: flex;
        flex: .5;
        float: right;
        width: min-content;
        transition: all var(--nav-menu-on-scroll-change-speed);
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .nav-menu-right-section {
        display: flex;
    }
}

/* END */

/* separator */

/* normal */
@media screen and (min-width: 1301px){
    .nav-menu-vertical-separator {
        height: var(--nav-menu-vertical-separator-height);
        width: var(--nav-menu-vertical-separator-width);
        background-color: var(--nav-menu-vertical-separator-color);
        margin: auto var(--nav-menu-vertical-separator-margin) auto 0;
        border-radius: var(--nav-menu-vertical-separator-border-radius);
        transition: all var(--nav-menu-on-scroll-change-speed);
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .nav-menu-vertical-separator {
        display: none;
    }
}

.nav-menu-vertical-separator-scrolled {
    width: calc(var(--nav-menu-vertical-separator-width) * .66);
    height: calc(var(--nav-menu-vertical-separator-height) * .85);
    background-color: var(--nav-menu-on-scroll-items-color) !important;
}

/* END */

/* language options */

/* normal */
@media screen and (min-width: 1301px){
    .lang-options {
        flex: 1;
        display: flex;
        justify-content: center;
        position: relative;
        flex-direction: column;
        width: 2rem !important;
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .lang-options {
        display: none;
    }
}

.lang-current {
    height: min-content;
    margin-top: auto;
    margin-bottom: auto;
    color: var(--nav-menu-item-font-color);
    border: none;
    font-size: var(--lang-dropdown-font-size);
    padding: .5rem;
    transition: all var(--nav-menu-on-scroll-change-speed);
}

.lang-current-scrolled {
    font-size: calc(var(--lang-dropdown-font-size) / var(--nav-menu-on-scroll-font-decrease));
    color: var(--nav-menu-on-scroll-items-color);
    transition: all var(--nav-menu-on-scroll-change-speed);
}

.lang-dropdown-container {
    width: min-content;
    cursor: pointer;
}

.lang-dropdown {
    position: absolute;
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.55);
    background-color: white;
    margin-top: .5rem;
    border-radius: 7%;
    opacity: 0;
    overflow: hidden;
    transition: opacity var(--nav-menu-item-animation-speed);
    pointer-events: none;
}

.lang-dropdown-scrolled {
    top: 2.7vw;
    left: -.5rem;
}

.lang-options:hover .lang-dropdown {
    opacity: 1;
    pointer-events: all;
}

.lang-dropdown-option {
    font-size: var(--lang-dropdown-font-size);
    color: black;
    padding: .5rem;
    z-index: 3;
    opacity: 1;
    transition: color var(--nav-menu-item-animation-speed), background-color var(--nav-menu-item-animation-speed);
}

.lang-dropdown-option:hover {
    color: var(--lang-dropdown-hover-font-color);
    background-color: var(--lang-dropdown-hover-background-color);
}

/* end */

/* burger menu */
@media screen and (min-width: 1301px){
    .nav-hamburger-menu {
        display: none;
    }
}

/* phone/tablet */
@media screen and (max-width: 1300px){
    .nav-hamburger-menu {
        display: flex;
    }

    .nav-hamburger-menu svg{
        height: 3.5vh;
        width: auto;
    }
}

.nav-burger-menu-box-hidden {
    transition-delay: 0s;
}

.nav-burger-menu-box-show {
    transition: all var(--nav-burger-animation-speed) cubic-bezier(.9, 0, .4, 1);
}

.nav-burger-container {
    display: flex;
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 0;
    width: 100vw;
    height: calc(100vh - 100%);
}

.nav-burger-container.nav-burger-menu-box-hidden {
    pointer-events: none;
    cursor: crosshair;
}

.nav-burger-container.nav-burger-menu-box-show {
    pointer-events: auto !important;
    cursor: auto !important;
}

.nav-burger {
    display: flex;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--nav-menu-background-color-phone);
    transform: translateY(-100vh);
    opacity: 1 !important;
    transition: all var(--nav-burger-animation-speed) cubic-bezier(.9, 0, .4, 1) !important;
}

.nav-burger.nav-burger-menu-box-show {
    transform: translateY(0) !important;
}


.nav-burger-container nav {
    display: flex;
    flex-direction: column;
    height: calc(100% - 20vh);
    width: 100%;
    margin: 10vh 0;
    justify-content: space-between;
    align-items: center;
}

.nav-burger-container nav a {
    font-size: var(--nav-burger-menu-font-size);
    color: var(--nav-burger-menu-font-color);
    letter-spacing: var(--nav-burger-menu-font-letter-spacing);
    line-height: var(--nav-burger-menu-font-letter-line-height);
}

.nav-burger-container nav a {
    font-size: calc(var(--nav-burger-menu-font-size) * .8);
}

.nav-burger-link {
    display: block;
    opacity: 0;
    transition: all calc(var(--nav-burger-animation-speed) * .5) linear;
}

.nav-burger-link.nav-burger-menu-box-show{
    opacity: 1 !important;
}

.nav-burger-link.nav-burger-menu-box-show:nth-child(1){
    transition-delay: calc(var(--nav-burger-animation-speed) * .6);
}

.nav-burger-link.nav-burger-menu-box-show:nth-child(2){
    transition-delay: calc(var(--nav-burger-animation-speed) * .8);
}

.nav-burger-link.nav-burger-menu-box-show:nth-child(3){
    transition-delay: calc(var(--nav-burger-animation-speed) * 1);
}

.nav-burger-link.nav-burger-menu-box-show:nth-child(4){
    transition-delay: calc(var(--nav-burger-animation-speed) * 1.2);
}

.nav-burger-link.nav-burger-menu-box-show:nth-child(5){
    transition-delay: calc(var(--nav-burger-animation-speed) * 1.4);
}

.nav-burger-box-lang-container {
    opacity: 0;
    display: inline-flex;
    gap: var(--nav-burger-lang-options-gap);
}

.nav-burger-box-lang-container.nav-burger-menu-box-show {
    transition-delay: calc(var(--nav-burger-animation-speed) * 1.4);
    opacity: 1 !important;
}

.nav-burger-box-lang-option {
    font-size: var(--nav-burger-lang-menu-font-size);
    color: var(--nav-burger-lang-menu-font-color);
    letter-spacing: var(--nav-burger-lang-menu-font-letter-spacing);
    line-height: var(--nav-burger-lang-menu-font-letter-line-height);
}

.nav-burger-box-lang-option-current {
    font-size: var(--nav-burger-lang-menu-current-font-size);
    color: var(--nav-burger-lang-menu-current-font-color);
}

/* END */
