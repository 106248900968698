:root {

    /* Top spacing */
    --solutions-background-color: #3c4856;
    --solutions-top-spacing: 8vh;

    /* General */
    --solutions-background: #272f39;
    --solutions-height: 90vh;
}

#solutions {
    display: flex;
    flex-direction: column;
    min-height: var(--solutions-height);
    background-color: var(--solutions-background);
}

.solutions-main-section {
    display: flex;
    height: 80vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.solutions-top-spacing {
    background-color: var(--solutions-background-color);
    min-height: var(--solutions-top-spacing);
}

/* Hexagons */

.solutions-hexagon-row {
    display: flex;
}

.solutions-hexagon {
    width: 200px;
    height: 165px;
    background-color: transparent;
    perspective: 1000px;
}

.solutions-hexagon-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .8s;
    transform-style: preserve-3d;
    will-change: transform;
}

.solutions-hexagon:hover .solutions-hexagon-inner {
    transform: rotateX(180deg);
}

.solutions-hexagon-noanim {
    transform: none !important;
}

.solutions-hexagon-clipped-left {
    clip-path: polygon(calc(50% + 10px) 0, 100% 0, 100% 100%, calc(50% + 10px) 100%);
}

.solutions-hexagon-clipped-right {
    clip-path: polygon(0 0, calc(50% - 10px) 0, calc(50% - 10px) 100%, 0 100%);
}

.solutions-hexagon-front, .solutions-hexagon-back {
    position: absolute;
    width: 100%;
    height: 120%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.solutions-hexagon-front-inner, .solutions-hexagon-back-inner img {
    margin: 0;
    top: 0;
    right: 0;
    z-index: -1;
    position: absolute;
}


.solutions-hexagon-front {
    color: #ffffff;
}

.solutions-hexagon-front-inner > img {
    opacity: .8;
}

.solutions-hexagon-back {
    color: rgb(255, 255, 255);
    font-weight: bolder;
    font-size: 1.2em;
    transform: rotateX(180deg);
}

/* END */

/* Hexagon images */

.solutions-hexagon-icon img{
    width: 50%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
}

/* END */

/* Window Pop-up */

.solutions-popup-container {
    position: absolute;
    display: flex;
    margin: auto;
    width: 60vw;
    height: 60vh;
    background-color: rgba(60, 72, 86, 0.94);
    border-radius: 20px;
    z-index: 1;
    overflow: hidden;
}

.solutions-popup-container-hidden {
    display: none;
    pointer-events: none;
}

.solutions-popup-inner-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.solutions-popup-top-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
    height: 7%;
    background-color: rgba(86, 82, 60, 0.94);
}

.solutions-popup-top-bar-title {
    display: flex;
    align-self: center!important;
    justify-self: center!important;
    grid-column: 2;
}

.solutions-popup-top-bar-exit-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 10%;
}

.solutions-popup-top-bar-exit {
    color: gray;
}

.solutions-popup-top-bar-exit:hover {
    color: red;
}

.solutions-popup-main-frame {
    display: flex;
    width: auto;
    height: 93%;
    justify-content: center;
    align-items: center;
    background-color: rgba(67, 86, 60, 0.94);
}

/* END */
