/* variables */

:root {

    /* positioning */
    --footer-background-color: #3c4856;
    --footer-padding-top: 15vh;
    --footer-padding-bot: 10vh;
    --footer-block-items-gap-tablet: .5vh;
    --footer-block-width: 80%;
    --footer-block-line-space: 10%;
    --footer-container-logo-width: 35%;
    --footer-container-row-gap: 5vh;
    --footer-container-column-gap: 10vw;
    --footer-container-margin: 10vw;

    /* logo */
    --footer-logo-width: 15vh;

    /* horizontal lines */
    --footer-horizontal-line-color: #ffffff;
    --footer-horizontal-line-width: 13vw;
    --footer-horizontal-line-height: 2px;
    --footer-horizontal-line-height-phone: 8vh;
    --footer-horizontal-line-radius: 20%;

    /* titles */
    --footer-block-title-color: #ffffff;
    --footer-block-title-font-size: 2vh;
    --footer-block-title-top-margin: 20%;
    --footer-block-title-bot-margin: 30%;
    --footer-block-title-top-margin-tablet: .5vh;
    --footer-block-title-bot-margin-tablet: 1vh;

    /* text */
    --footer-block-text-color: #ffffff;
    --footer-block-text-font-size: 2vh;

    /* links */
    --footer-block-links-separator-height: 1rem;
    --footer-block-links-separator-width: 2px;
    --footer-block-links-separator-spacing: 1rem;
    --footer-block-links-separator-radius: 20%;
    --footer-linkedin-hover-color: #0078b6;
    --footer-twitter-hover-color: #1d9ff0;

}

/* END */

footer {
    background-color: var(--footer-background-color);
}

.footer-container {
    display: grid;
    padding-top: var(--footer-padding-top);
    padding-bottom: var(--footer-padding-bot);
}

.footer-block {
    margin: 0 auto;
}

.footer-horizontal-line {
    background-color: var(--footer-horizontal-line-color);
    border-radius: var(--footer-horizontal-line-radius);
}

.footer-block-title {
    color: var(--footer-block-title-color);
    font-size: var(--footer-block-title-font-size);
    margin-top: var(--footer-block-title-top-margin);
    margin-bottom: var(--footer-block-title-bot-margin);
}

.footer-block-text-row {
    color: var(--footer-block-text-color);
    font-size: var(--footer-block-text-font-size);
}

/* normal */
@media screen and (min-width: 1301px){
    .footer-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .footer-block {
        display: flex;
        flex-direction: column;
    }

    .footer-horizontal-line {
        width: var(--footer-horizontal-line-width);
        height: var(--footer-horizontal-line-height);
    }

    .footer-block-title {
        margin-top: var(--footer-block-title-top-margin);
        margin-bottom: var(--footer-block-title-bot-margin);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .footer-container {
        grid-template-columns: var(--footer-container-logo-width) auto;
        align-items: center;
        row-gap: var(--footer-container-row-gap);
        margin: 0 var(--footer-container-margin);
    }

    .footer-block:nth-child(1) {
        grid-column: 1;
        grid-row: 1 / span 3;
        justify-content: center;
        display: block;
    }

    .footer-block {
        width: var(--footer-block-width);
        padding: 0 calc((100% - var(--footer-block-width)) / 2);
        display: grid;
        grid-template-columns: var(--footer-block-line-space) calc(100% - var(--footer-block-line-space));
        grid-template-rows: repeat(3, auto);
        row-gap: var(--footer-block-items-gap-tablet);
        grid-column: 2;
        align-content: start;
    }

    .footer-horizontal-line {
        height: var(--footer-horizontal-line-width);
        width: var(--footer-horizontal-line-height);
        align-self: center;
        grid-column: 1;
        grid-row: 1 / span 3;
    }

    .footer-block-title {
        margin-top: var(--footer-block-title-top-margin-tablet);
        margin-bottom: var(--footer-block-title-bot-margin-tablet);
        grid-column: 2;
        grid-row: 1;
    }

    .footer-block-text-row:nth-child(1) {
        grid-column: 2;
        grid-row: 2;
    }

    .footer-block-text-row:nth-child(2) {
        grid-column: 2;
        grid-row: 3;

    }
}

/* phone */
@media screen and (max-width: 650px){
    .footer-container {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap: var(--footer-container-row-gap);
        margin: 0 var(--footer-container-margin);
    }

    .footer-block {
        width: var(--footer-block-width);
        padding: 0 calc((100% - var(--footer-block-width)) / 2);
        display: grid;
        grid-template-columns: var(--footer-block-line-space) calc(100% - var(--footer-block-line-space));
        grid-template-rows: repeat(3, auto);
        row-gap: var(--footer-block-items-gap-tablet);
        align-content: start;
    }

    .footer-block:nth-child(1) {
        justify-content: center;
        display: flex;
    }

    .footer-horizontal-line {
        height: var(--footer-horizontal-line-height-phone);
        width: var(--footer-horizontal-line-height);
        align-self: center;
        grid-column: 1;
        grid-row: 1 / span 3;
    }

    .footer-block-title {
        margin-top: var(--footer-block-title-top-margin-tablet);
        margin-bottom: var(--footer-block-title-bot-margin-tablet);
    }
}

.footer-logo-container img {
    width: var(--footer-logo-width);
    height: auto;
}

.footer-block-links-row {
    display: flex;
    width: 100%;
    align-items: center;
}

.footer-block-text-link {
    z-index: 1;
}

.footer-block-text-link a {
    color: var(--footer-block-text-color);
    font-size: var(--footer-block-text-font-size);
}

#Twitter-link:hover a{
    color: var(--footer-twitter-hover-color) !important;
}

#Linkedin-link:hover a{
    color: var(--footer-linkedin-hover-color) !important;
}

.footer-block-text-vertical-separator {
    background-color: var(--footer-block-text-color);
    width: var(--footer-block-links-separator-width);
    height: var(--footer-block-links-separator-height);
    margin: 0 var(--footer-block-links-separator-spacing);
    border-radius: var(--footer-block-links-separator-radius);
}