/* variables */

:root {
    /* animation */
    --homepage-on-scroll-animations-delay: 300ms;

    /* background video and overlay */
    --homepage-video-height: 90vh;
    --homepage-video-overlay-color: rgba(0, 0, 0, 0.35);
    --homepage-video-overlay-company-name-font-size: 3vw;
    --homepage-video-overlay-incentive-font-size: 1.5vw;
    --homepage-video-overlay-text-padding: 5vw;
    --homepage-video-overlay-text-shadow: 0 0 .5rem black;

    /* background video and overlay PHONE */
    --homepage-video-overlay-company-name-font-size-phone: 6vw;
    --homepage-video-overlay-incentive-font-size-phone: 3.5vw;


    /* tiles section */
    --homepage-tiles-section-height: 80vh;
    --homepage-tiles-border-color: rgba(170, 179, 186, 0.45);
    --homepage-tiles-border-thickness: 1px;
    --homepage-tiles-question-font-color: #000000;
    --homepage-tiles-title-font-color: #000000;
    --homepage-tiles-text-font-color: #000000;
    --homepage-tiles-items-spacing: 1vh;
    --homepage-tiles-question-font-size: 4vw;
    --homepage-tiles-title-font-size: 1.4rem;
    --homepage-tiles-text-font-size: 1vw;
    --homepage-tiles-svg-size: 3.5vw;

    /* tiles section TABLET */
    --homepage-tiles-question-font-size-tablet: 8vw;
    --homepage-tiles-title-font-size-tablet: 3vw;
    --homepage-tiles-text-font-size-tablet: 2vw;
    --homepage-tiles-svg-size-tablet: 10vw;

    /* tiles section PHONE */
    --homepage-tiles-question-font-size-phone: 11vw;
    --homepage-tiles-title-font-size-phone: 6vw;
    --homepage-tiles-text-font-size-phone: 4vw;
    --homepage-tiles-svg-size-phone: 14vw;

    /* tiles section on scroll animation */
    --homepage-slider-tiles-animation-speed: 1.5s;
    --homepage-slider-tiles-animation-travel-distance: -10%;


    /* two sides section */
    --homepage-two-sides-section-border-width: 2px;
    --homepage-two-sides-section-border-color: #000000;
    --homepage-two-sides-section-text-sides-margin: 20%;
    --homepage-two-sides-section-separator-width: 5vw;
    --homepage-two-sides-section-separator-height: 3px;
    --homepage-two-sides-section-separator-radius: 25%;
    --homepage-two-sides-section-text-spacing: 7%;
    --homepage-two-sides-section-separator-color: #000000;
    --homepage-two-sides-section-separator-top-margin: 1.5vh;
    --homepage-two-sides-section-separator-bot-margin: 3vh;
    --homepage-two-sides-section-title-color: #000000;
    --homepage-two-sides-section-title-font-size: 1.5vw;
    --homepage-two-sides-section-text-color: #000000;
    --homepage-two-sides-section-text-font-size: 1vw;

    /* two sides section TABLET */
    --homepage-two-sides-section-text-sides-margin-tablet: 10%;
    --homepage-two-sides-section-cover-color: rgba(0, 0, 0, 0.65);
    --homepage-two-sides-section-text-spacing-tablet: 7%;
    --homepage-two-sides-section-separator-color-tablet: #ffffff;
    --homepage-two-sides-section-separator-width-tablet: 15vw;
    --homepage-two-sides-section-separator-top-margin-tablet: 1vh;
    --homepage-two-sides-section-separator-bot-margin-tablet: 2vh;
    --homepage-two-sides-section-title-color-tablet: #ffffff;
    --homepage-two-sides-section-title-font-size-tablet: 3vw;
    --homepage-two-sides-section-text-color-tablet: #ffffff;
    --homepage-two-sides-section-text-font-size-tablet: 2vw;

    /* two sides section PHONE */
    --homepage-two-sides-section-phone-background: #141416;
    --homepage-two-sides-section-text-sides-margin-phone: 5%;
    --homepage-two-sides-section-text-spacing-phone: 7%;
    --homepage-two-sides-section-title-color-phone: #ffffff;
    --homepage-two-sides-section-title-font-size-phone: 7vw;
    --homepage-two-sides-section-text-color-phone: #ffffff;
    --homepage-two-sides-section-text-font-size-phone: 4.4vw;

    /* two sides section on scroll animation */
    --homepage-two-sides-section-animation-speed: 1s;
    --homepage-two-sides-section-title-travel-distance: -30%;
    --homepage-two-sides-section-separator-travel-distance: 10%;
    --homepage-two-sides-section-text-travel-distance: 50%;


    /* slider section */
    --homepage-slider-title-font-size: 3vh;
    --homepage-slider-title-font-color: #000000;
    --homepage-slider-title-top-margin: 5vh;
    --homepage-slider-arrow-color: #000000;
    --homepage-slider-arrow-border-width: .3vw;
    --homepage-slider-arrow-padding: .5vw;
    --homepage-slider-arrow-margin: 0 1rem .2rem 0;

    /* slider section TABLET */
    --homepage-slider-title-font-size-tablet: 2.75vh;
    --homepage-slider-arrow-color-tablet: #000000;
    --homepage-slider-arrow-border-width-tablet: .3vw;
    --homepage-slider-arrow-padding-tablet: 2.5vw;
    --homepage-slider-arrow-margin-tablet: 0 1vw 0 1vw;

    /* slider section PHONE */
    --homepage-slider-title-font-size-phone: 2.5vh;

}

/* END */

/* general purpose */

.homepage {
    overflow: hidden;
}

.spacing {
    height: 10vh;
}

.arrow {
    display: inline-block;
}

/* normal */
@media screen and (min-width: 1300px){
    .arrow {
        border: solid var(--homepage-slider-arrow-color);
        border-width: 0 var(--homepage-slider-arrow-border-width) var(--homepage-slider-arrow-border-width) 0;
        padding: var(--homepage-slider-arrow-padding);
        margin: var(--homepage-slider-arrow-margin);
    }
}

/* tablet */
@media screen and (max-width: 1299px){
    .arrow {
        border: solid var(--homepage-slider-arrow-color-tablet);
        border-width: 0 var(--homepage-slider-arrow-border-width-tablet) var(--homepage-slider-arrow-border-width-tablet) 0;
        padding: var(--homepage-slider-arrow-padding-tablet);
        margin: var(--homepage-slider-arrow-margin-tablet);
    }
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.hidden {
    opacity: 0;
    transition: all 1s;
    transition-delay: var(--homepage-on-scroll-animations-delay);
}

.show {
    opacity: 1 !important;
}

/* END */

/* background video section */

.homepage-video-container {
    position: relative;
    overflow: hidden;
    max-height: var(--homepage-video-height);
    line-height: 0;
}

.homepage-video {
    width: 100%;
}

.homepage-video-overlay {
    position: absolute;
    background: var(--homepage-video-overlay-color);
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.homepage-video-overlay-text-container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-shadow: var(--homepage-video-overlay-text-shadow);
    line-height: normal;
}

/* normal */
@media screen and (min-width: 1301px){
    .homepage-mobile-spacing {
        display: none;
    }

    .homepage-video-overlay-text-container {
        justify-content: center;
        padding-left: var(--homepage-video-overlay-text-padding);
    }

    .homepage-video-overlay-company-name {
        max-width: 30vw;
        font-size: var(--homepage-video-overlay-company-name-font-size);
    }

    .homepage-video-overlay-incentive {
        font-size: var(--homepage-video-overlay-incentive-font-size);
    }
}

/* phone */
@media screen and (max-width: 1300px){
    .homepage-mobile-spacing {
        display: block;
        background-color: var(--nav-menu-background-color-phone);
        width: 100%;
        height: var(--header-max-height);
        transition: all 0s;
    }

    .homepage-video-overlay-text-container {
        align-items: center;
    }

    .homepage-video-overlay-company-name {
        font-size: var(--homepage-video-overlay-company-name-font-size-phone);
    }

    .homepage-video-overlay-incentive {
        font-size: var(--homepage-video-overlay-incentive-font-size-phone);
    }
}

/* END */

/* tiles section */

.homepage-tiles-section {
    min-height: var(--homepage-tiles-section-height);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(650px, 1fr));
}

.homepage-tiles-inner-section {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.homepage-tiles-inner-section .hidden {
    transform: translateX(var(--homepage-slider-tiles-animation-travel-distance));
    transition: all var(--homepage-slider-tiles-animation-speed);
    transition-delay: var(--homepage-on-scroll-animations-delay);
}

.homepage-tile {
    min-height: calc(var(--homepage-tiles-section-height) / 2);
    min-width: 50vw;
}

.homepage-tiles-inner-section .show {
    transform: translateX(0);
}

.homepage-tiles-inner-container {
    display: flex;
    justify-content: center;
}

.homepage-tiles-inner-container img{
    height: auto;
}

.homepage-tiles-text-container {
    display: flex;
    flex-direction: column;
}

.homepage-tiles-title {
    color: var(--homepage-tiles-title-font-color);
    margin-bottom: var(--homepage-tiles-items-spacing);
}

.homepage-tiles-text {
    color: var(--homepage-tiles-text-font-color);
}

/* normal */
@media screen and (min-width: 1300px){
    .homepage-tiles-inner-container{
        flex-direction: column;
    }

    .homepage-tiles-inner-container img {
        width: var(--homepage-tiles-svg-size);
        margin-bottom: var(--homepage-tiles-items-spacing);
    }

    .homepage-tiles-inner-container {
        margin: 10% 0;
        max-width: 55%;
    }

    .homepage-tiles-section-top-left{
        border-width: 0 var(--homepage-tiles-border-thickness) 0 0;
        border-style: solid;
        border-color: var(--homepage-tiles-border-color);
        font-size: var(--homepage-tiles-question-font-size);
    }

    .homepage-tiles-title {
        font-size: var(--homepage-tiles-title-font-size);
    }

    .homepage-tiles-text {
        font-size: var(--homepage-tiles-text-font-size);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .homepage-tiles-inner-container{
        flex-direction: row;
    }

    .homepage-tiles-text-container {
        margin-left: 5vw;
    }

    .homepage-tiles-inner-container {
        margin: 10% 0;
        max-width: 75%;
    }

    .homepage-tiles-inner-container img {
        width: var(--homepage-tiles-svg-size-tablet);
    }

    .homepage-tiles-section-top-left{
        border-width: 0 0 var(--homepage-tiles-border-thickness) 0;
        border-style: solid;
        border-color: var(--homepage-tiles-border-color);
        font-size: var(--homepage-tiles-question-font-size-tablet);
    }

    .homepage-tiles-title {
        font-size: var(--homepage-tiles-title-font-size-tablet);
    }

    .homepage-tiles-text {
        font-size: var(--homepage-tiles-text-font-size-tablet);
    }
}

/* phone */
@media screen and (max-width: 650px){
    .homepage-tiles-section {
        display: flex !important;
        flex-direction: column !important;
    }

    .homepage-tiles-inner-container{
        flex-direction: column;
    }

    .homepage-tiles-inner-container {
        margin: 10% 0;
        max-width: 85%;
    }

    .homepage-tiles-inner-container img {
        width: var(--homepage-tiles-svg-size-phone);
        margin-bottom: 0;
    }

    .homepage-tiles-section-top-left{
        border-width: 0 0 var(--homepage-tiles-border-thickness) 0;
        border-style: solid;
        border-color: var(--homepage-tiles-border-color);
        font-size: var(--homepage-tiles-question-font-size-phone);
    }

    .homepage-tiles-title {
        font-size: var(--homepage-tiles-title-font-size-phone);
    }

    .homepage-tiles-text {
        font-size: var(--homepage-tiles-text-font-size-phone);
    }
}

.homepage-tiles-section-top-left {
    color: var(--homepage-tiles-question-font-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-tiles-section-bot-left {
    border-width: var(--homepage-tiles-border-thickness) var(--homepage-tiles-border-thickness) 0 0;
    border-style: solid;
    border-color: var(--homepage-tiles-border-color);
}

.homepage-tiles-section-bot-right {
    border-width: var(--homepage-tiles-border-thickness) 0 0 0;
    border-style: solid;
    border-color: var(--homepage-tiles-border-color);
}

/* END */

/* two sides section */

.homepage-two-sides-section {
    display: flex;
    border-width: var(--homepage-two-sides-section-border-width) 0 var(--homepage-two-sides-section-border-width) 0;
    border-style: solid;
    border-color: var(--homepage-two-sides-section-border-color);
    position: relative;
}

.homepage-two-sides-section-img-container {
    overflow: hidden;
    position: relative;
    line-height: 0;
}

.homepage-two-sides-section-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.homepage-two-sides-section-img-cover{
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--homepage-two-sides-section-cover-color);
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* normal */
@media screen and (min-width: 1300px){
    .homepage-two-sides-section-img-container{
        display: flex;
        justify-content: center;
        width: 50%;
    }

    .homepage-two-sides-section-img-container img{
        max-width: 120%;
        height: 100%;
        object-fit: cover;
    }

    .homepage-two-sides-section-img-cover {
        display: none;
        width: 0;
        height: 0;
    }

    .homepage-two-sides-section-text-inner-container{
        margin:
                var(--homepage-two-sides-section-text-spacing)
                var(--homepage-two-sides-section-text-sides-margin);
    }

    .homepage-two-sides-section-text-title {
        font-size: var(--homepage-two-sides-section-title-font-size);
        color: var(--homepage-two-sides-section-title-color);
    }

    .homepage-two-sides-section-text-separator {
        background-color: var(--homepage-two-sides-section-separator-color);
        width: var(--homepage-two-sides-section-separator-width);
        margin-top: var(--homepage-two-sides-section-separator-top-margin);
        margin-bottom: var(--homepage-two-sides-section-separator-bot-margin);
    }

    .homepage-two-sides-section-text {
        font-size: var(--homepage-two-sides-section-text-font-size);
        color: var(--homepage-two-sides-section-text-color);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .homepage-two-sides-section-img-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .homepage-two-sides-section-img-container img{
        width: 200%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    .homepage-two-sides-section-text-inner-container{
        margin:
                var(--homepage-two-sides-section-text-spacing-tablet)
                var(--homepage-two-sides-section-text-sides-margin-tablet);
    }

    .homepage-two-sides-section-text-title {
        font-size: var(--homepage-two-sides-section-title-font-size-tablet);
        color: var(--homepage-two-sides-section-title-color-tablet);
    }

    .homepage-two-sides-section-text-separator {
        background-color: var(--homepage-two-sides-section-separator-color-tablet);
        width: var(--homepage-two-sides-section-separator-width-tablet);
        margin-top: var(--homepage-two-sides-section-separator-top-margin-tablet);
        margin-bottom: var(--homepage-two-sides-section-separator-bot-margin-tablet);
    }

    .homepage-two-sides-section-text {
        font-size: var(--homepage-two-sides-section-text-font-size-tablet);
        color: var(--homepage-two-sides-section-text-color-tablet);
    }
}

/* phone */
@media screen and (max-width: 650px){
    .homepage-two-sides-section{
        background-color: var(--homepage-two-sides-section-phone-background);
    }

    .homepage-two-sides-section-text-inner-container{
        flex-direction: row !important;
        margin:
                var(--homepage-two-sides-section-text-spacing-phone)
                var(--homepage-two-sides-section-text-sides-margin-phone);
    }

    .homepage-two-sides-section-img-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .homepage-two-sides-section-img-container img{
        display: none;
    }

    .homepage-two-sides-section-text-title {
        align-self: center;
        writing-mode: vertical-lr;
        rotate: 180deg;
        font-size: var(--homepage-two-sides-section-title-font-size-phone);
        color: var(--homepage-two-sides-section-title-color-phone);
        margin-right: 1rem;
    }

    .homepage-two-sides-section-text-separator {
        display: none;
    }

    .homepage-two-sides-section-text {
        font-size: var(--homepage-two-sides-section-text-font-size-phone);
        color: var(--homepage-two-sides-section-text-color-phone);
    }
}

.homepage-two-sides-section-text-inner-container {
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.homepage-two-sides-section-text-title .hidden {
    transform: translateX(var(--homepage-two-sides-section-title-travel-distance));
    transition: all var(--homepage-two-sides-section-animation-speed);
    transition-delay: var(--homepage-on-scroll-animations-delay);
}

.homepage-two-sides-section-text-title .show {
    transform: translateX(0);
}

.homepage-two-sides-section-text-separator-container {
    overflow: hidden;
}

.homepage-two-sides-section-text-separator-container .hidden {
    transform: translateX(var(--homepage-two-sides-section-separator-travel-distance));
    transition: all var(--homepage-two-sides-section-animation-speed);
    transition-delay: var(--homepage-on-scroll-animations-delay);
}

.homepage-two-sides-section-text-separator-container .show {
    transform: translateX(0);
}

.homepage-two-sides-section-text-separator {
    border-radius: var(--homepage-two-sides-section-separator-radius);
    height: var(--homepage-two-sides-section-separator-height);
}

.homepage-two-sides-section-text .hidden {
    transform: translateY(var(--homepage-two-sides-section-text-travel-distance));
    transition: all var(--homepage-two-sides-section-animation-speed);
    transition-delay: var(--homepage-on-scroll-animations-delay);
}

.homepage-two-sides-section-text .show {
    transform: translateY(0);
}

/* END */

/* company full name section */

.company-full-name {
    font-family: Tahoma, sans-serif;
    font-weight: lighter;
    font-size: 3.5rem;
    text-align: center;
}

.and-sign {
    font-family: Tahoma, sans-serif;
    font-size: inherit;
    color: white;
}

/* END */

/* short description section */

.short-description {
    display: flex;
    flex-wrap: wrap;
    margin: 0vw 3vw 0vw 3vw;
}

.short-description-spacing {
    height: 10vh;
}

.short-description-text {
    flex: 1;
    min-width: 200px;
    max-width: 50vw;

    font-family: Playfair, sans-serif;
    font-size: 3rem;
}

.short-description-links {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    font-family: Playfair, sans-serif;
    font-size: 3.5rem;
}

/* END */

/* incentive */

.incentive {
    font-family: Playfair, sans-serif;
    font-weight: lighter;
    font-size: 3.5rem;
    text-align: center;
}

/* END */

/* reset section */

.reset {
    display: flex;
    flex-wrap: wrap;
    margin: 0 3vw 0 3vw;
}

.reset-logo {
    width: 40vw;
    height: auto;
}

.reset-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    font-family: Playfair, sans-serif;
    font-size: 3.5rem;
}

.reset-link {
    display: inline-block;
    color: #2499ff;
    transition: color 0.5s ease;
}

.reset-link:hover {
    color: #67b9ff;
}

.reset-arrow {
    display: inline-block;
    transition: transform 0.5s ease, border 0.5s ease;
    border: solid #2499ff;
    border-width: 0 0.3vw 0.3vw 0;
}

.reset-link:hover > .reset-arrow {
    border: solid #67b9ff;
    border-width: 0 0.3vw 0.3vw 0;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* END */

/* sap b1 section */

.sap-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 3vw 0 3vw;
}

.sap-logo {
    width: 40vw;
    height: auto;
}

.sap-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    font-family: Playfair, sans-serif;
    font-size: 3.5rem;
}

.sap-link {
    display: inline-block;
    color: #2499ff;
    transition: color 0.5s ease;
}

.sap-link:hover {
    color: #67b9ff;
}

.sap-arrow {
    display: inline-block;
    transition: transform 0.5s ease, border 0.5s ease;
    border: solid #2499ff;
    border-width: 0 0.3vw 0.3vw 0;
}

.sap-link:hover > .sap-arrow {
    border: solid #67b9ff;
    border-width: 0 0.3vw 0.3vw 0;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

/* END */

/* contact */

.contact-box {
    width: 68rem;
    height: 25rem;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.contact-link {
    font-family: Playfair, sans-serif;
    font-weight: lighter;
    font-size: 17rem;
    text-align: center;
    color: #67b9ff;
    transition: text-shadow 0.5s ease-in-out, color 0.7s ease-in-out;
}

.contact-link:hover {
    text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.55);
    color: white;
}

@keyframes text-shadow-animation {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        color: #67b9ff;
    }
    100% {
        text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.55);
        color: white;
    }
}

/* END */

/* slider section */

.homepage-slider-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f7f7f7;
}

.homepage-slider-title {
    text-align: center;
    margin-top: var(--homepage-slider-title-top-margin);
    color: var(--homepage-slider-title-font-color);
}

/* normal */
@media screen and (min-width: 1300px){
    .homepage-slider-title {
        font-size: var(--homepage-slider-title-font-size);
    }
}

/* tablet */
@media screen and (min-width: 651px) and (max-width: 1299px){
    .homepage-slider-title {
        font-size: var(--homepage-slider-title-font-size-tablet);
    }
}

/* phone */
@media screen and (max-width: 650px) {
    .homepage-slider-title {
        font-size: var(--homepage-slider-title-font-size-phone);
    }
}

/* END */