:root {

    /* background */
    --contact-background-color: #3c4856;

    /* background overlay */
    --contact-background-overlay-color: rgba(0, 0, 0, 0.5);

    /* background overlay icon */
    --contact-background-overlay-icon-opacity: 15%;
    --contact-background-overlay-icon-position: 85% 50%;
    --contact-background-overlay-icon-size: 45%;

    /* page title */
    --contact-page-title-font-color: #ffffff;
    --contact-page-title-font-size: 5rem;
    --contact-page-title-letter-spacing: .05rem;
    --contact-page-title-line-height: 6rem;
    --contact-page-title-padding-top: 4rem;
    --contact-page-title-padding-bottom: 4rem;

    /* two sides section */
    --contact-two-sides-section-margin: 7vw;

    /* two sides section box */
    --contact-two-sides-section-box-margin: 3rem;
    --contact-two-sides-section-box-background-color: rgba(0, 0, 0, .5);
    --contact-two-sides-section-box-min-width: 80%;
    --contact-two-sides-section-box-min-height: 50vh;

    /* two sides section box menu */
    --contact-two-sides-section-box-menu-animation-speed: .5s;
    --contact-two-sides-section-box-menu-active-item-background-color: rgba(0, 0, 0, .5);
    --contact-two-sides-section-box-menu-inactive-item-background-color: rgba(255, 255, 255, .05);
    --contact-two-sides-section-box-menu-text-padding: .5rem 1.5rem;
    --contact-two-sides-section-box-menu-text-font-color: #ffffff;
    --contact-two-sides-section-box-menu-text-font-weight: lighter;
    --contact-two-sides-section-box-menu-text-font-size: 1.4rem;
    --contact-two-sides-section-box-menu-text-line-height: 2rem;
    --contact-two-sides-section-box-menu-text-letter-spacing: .05rem;

    /* two sides section box text container */
    --contact-two-sides-section-box-text-container-background-color: rgba(0, 0, 0, .5);
    --contact-two-sides-section-box-text-container-min-height: 40vh;
    --contact-two-sides-section-box-text-container-padding: 2rem;

    /* two sides section box text */
    --contact-two-sides-section-box-text-font-color: #ffffff;
    --contact-two-sides-section-box-text-font-weight: lighter;
    --contact-two-sides-section-box-text-font-size: 1.5rem;
    --contact-two-sides-section-box-text-line-height: 2rem;
    --contact-two-sides-section-box-text-letter-spacing: .05rem;
    --contact-two-sides-section-box-text-spacing: 2rem;

    /* empty section */
    --contact-two-sides-section-empty-border-prefab: 2px #4b3723 solid;

}

/* general */

.contact{
    background-color: var(--contact-background-color);
}

.contact-inner-container{
    position: relative;
}

/* normal */
@media screen and (min-width: 1300px){
    .contact{
        padding-bottom: var(--footer-padding-top);
    }
}

.contact-background{
    overflow: hidden;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.contact-background img {
    width: auto;
    min-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
}

.contact-background-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--contact-background-overlay-color);
}

/* normal */
@media screen and (min-width: 1300px){
    .contact-background-overlay-icon{
        opacity: var(--contact-background-overlay-icon-opacity);
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        background-image: url('./../img/offer/live-chat.svg');
        background-position: var(--contact-background-overlay-icon-position);
        background-repeat: no-repeat;
        background-attachment: local;
        background-size: var(--contact-background-overlay-icon-size);
        pointer-events: none;
    }

    .contact-background img {
        left: 0;
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .contact-background-overlay-icon{
        display: none;
        width: 0;
        height: 0;
    }

    .contact-background img {
        left: -50%;
    }
}

/* END */

/* navigation background */

.contact-navigation-background {
    display: flex;
    min-height: var(--offer-navinagtion-background-height);
    background-color: var(--offer-navinagtion-background-color);
}

/* END */

/* page title */

.contact-page-title{
    position: relative;
    color: var(--contact-page-title-font-color);
    font-size: var(--contact-page-title-font-size);
    letter-spacing: var(--contact-page-title-letter-spacing);
    line-height: var(--contact-page-title-line-height);
    text-align: center;
    padding-top: var(--contact-page-title-padding-top);
    padding-bottom: var(--contact-page-title-padding-bottom);
}

/* END */

/* two side section */

.contact-two-side-section-container{
    position: relative;
    display: flex;
    height: 100%;
}

.contact-two-side-section-box{
    margin: var(--contact-two-sides-section-box-margin);
    background-color: var(--contact-two-sides-section-box-background-color);
    min-width: var(--contact-two-sides-section-box-min-width);
    min-height: var(--contact-two-sides-section-box-min-height);
    display: flex;
    flex-direction: column;
}

.contact-two-side-section-box-menu{
    display: flex;
    align-items: center;
    height: fit-content;
}

.contact-two-side-section-box-menu-item{
    display: inline-flex;
    width: max-content;
    cursor: pointer;
    transition: all var(--contact-two-sides-section-box-menu-animation-speed);
}

.contact-two-side-section-box-menu-item-active{
    background-color: var(--contact-two-sides-section-box-menu-active-item-background-color);
}
.contact-two-side-section-box-menu-item-inactive:hover{
    background-color: var(--contact-two-sides-section-box-menu-inactive-item-background-color);
}

.contact-two-side-section-box-menu-item-text{
    padding: var(--contact-two-sides-section-box-menu-text-padding);
    color: var(--contact-two-sides-section-box-menu-text-font-color);
    font-size: var(--contact-two-sides-section-box-menu-text-font-size);
    font-weight: var(--contact-two-sides-section-box-menu-text-font-weight);
    line-height: var(--contact-two-sides-section-box-menu-text-line-height);
    letter-spacing: var(--contact-two-sides-section-box-menu-text-letter-spacing);
}

.contact-two-side-section-box-text{
    background-color: var(--contact-two-sides-section-box-text-container-background-color);
    min-height: var(--contact-two-sides-section-box-text-container-min-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--contact-two-sides-section-box-text-container-padding);
}

.contact-two-side-section-box-text-line{
    color: var(--contact-two-sides-section-box-text-font-color);
    font-size: var(--contact-two-sides-section-box-text-font-size);
    font-weight: var(--contact-two-sides-section-box-text-font-weight);
    line-height: var(--contact-two-sides-section-box-text-line-height);
    letter-spacing: var(--contact-two-sides-section-box-text-letter-spacing);
}

.contact-two-side-section-box-text-spacing {
    height: var(--contact-two-sides-section-box-text-spacing);
}


/* normal */
@media screen and (min-width: 1300px){
    .contact-two-side-section-empty{
        width: 50%;
        height: 100%;
        min-height: 70vh;
        border-left: var(--contact-two-sides-section-empty-border-prefab);
        border-top: var(--contact-two-sides-section-empty-border-prefab);
    }

    .contact-two-side-section-box-container{
        width: 50%;
        height: 100%;
    }

    .contact-two-side-section-container{
        width: calc(100% - var(--contact-two-sides-section-margin) * 2);
        margin-left: var(--contact-two-sides-section-margin);
        margin-right: var(--contact-two-sides-section-margin);
    }
}

/* tablet/phone */
@media screen and (max-width: 1299px){
    .contact-two-side-section-empty{
        display: none;
        width: 0;
        height: 0;
    }

    .contact-two-side-section-box-container{
        width: 100%;
        height: 100%;
    }

    .contact-two-side-section-container{
        width: 100%;
        margin: 0 auto;
    }
}

/* END */